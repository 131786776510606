@import './variables.scss';

.app {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  color: $fontColor;
  overflow: auto;

  .app-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $pageBgColor;
    overflow: auto;

    .app-header {
      position: fixed;
      top: 0;
      display: flex;
      align-items: center;
      padding-left: 20px;
      width: 100%;
      height: $appHeaderHeight;
      background-color: $headerBgColor;
      border-bottom: $appBorder;
      z-index: 21;

      .title {
        color: #e45c2b;
        font-size: 0.75rem;
        margin-left: 20px;
      }

      .divider {
        width: 1px;
        height: 50%;
        background-color: #434343;
        margin: 0 $padding;
      }

      .progress-wrapper {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .avatar-wrapper {
        position: absolute;
        right: 3rem;
        cursor: pointer;
        z-index: 10;

        .a-avatar {
          background-color: $avatarBgColor;
          width: 32px;
          height: 32px;
          color: black;
          font-weight: bold;
        }
      }

      .question-icon {
        color: $activeColor;
        font-size: 22px;
        position: absolute;
        right: 6.5rem;
        cursor: pointer;
        z-index: 10;
      }
    }

    .app-body {
      position: relative;
      margin-top: $appHeaderHeight;
      // width: 100%;
      min-height: calc(100% - #{$appHeaderHeight+$appFooterHeight});

      .app-content {
        position: relative;
        // padding: 2rem 3rem 8rem;
        // padding: 2rem 3rem 0rem; // bin.b,zhang
        min-height: calc(100vh - #{$appHeaderHeight + $appFooterHeight});
        overflow: hidden;

        .page-wrapper {
          position: relative;
          min-height: calc(100vh - #{$appHeaderHeight + $appFooterHeight});
          width: 100%;
          padding: $padding $padding * 1.5;
          background-color: $pageBgColor;
        }
      }

      .no-bottom-padding {
        padding-bottom: 0;
      }

      .app-sidebar {
        background-color: $sideBarBgColor;
        position: fixed;
        left: 0;
        top: 0;
        height: calc(100% + #{$appHeaderHeight});
        width: $appSideBarWidth;
        border-right: $appBorder;
        z-index: 22;

        .app-sidebar-body {
          height: calc(100% - #{$appHeaderHeight});
          position: relative;

          .nav-bar {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              list-style: none;
            }

            .icon-wrapper {
              .a-font-27 {
                width: 27px;
              }

              .a-font-20 {
                width: 20px;
              }

              .a-font-24 {
                width: 24px;

                &.icon-bulb-outline {
                  background: url('../assets/imgs/Insight.png') no-repeat;
                  background-size: 24px;
                }

                &.icon-bulb-fill {
                  background: url('../assets/imgs/Insight fill.png') no-repeat;
                  background-size: 24px;
                }
              }

              .a-font-21 {
                width: 21px;
              }

              .a-font-33 {
                width: 33px;
              }

              color: $iconFontColor;
              height: auto;
            }

            .nav-bar-item {
              font-size: 0.875rem;
              height: 76px;
              text-transform: capitalize;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #7c7c7c;
              border-bottom: 2px solid #000;
              text-decoration: none;

              .a-tooltip-ref {
                width: 100%;
                height: 100%;
                top: 64px !important;

                .a-tooltip-ref-inner {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
                }
              }

              > li {
                width: 100%;
                display: flex;
                flex-direction: column;
                height: 51px;
                justify-content: space-evenly;

                .icon-wrapper {
                  text-align: center;
                }

                .label-wrapper {
                  text-align: center;
                  font-size: 10px;
                  line-height: 12px;
                  color: $iconFontColor;
                  text-transform: capitalize;
                }

                .label-ellipsis {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                }
              }

              &.is-active {
                background-color: #050505;

                > li .label-wrapper {
                  color: $fontColor !important;
                }
              }

              &:hover {
                background-color: #050505;
                color: rgba($color: white, $alpha: 0.6);
              }
            }
          }
          .beta-version-tag {
            padding: 0 12px;
            color: #7d7d7d;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
            position: absolute;
            bottom: 80px;
          }
        }
      }
    }
  }
}

.avatar-menu-wrapper {
  z-index: 21;

  .avatar-menu {
    padding: 8px;

    .avatar-menu-item {
      border-bottom: 1px solid white;
      width: 100%;
      font-size: 0.75rem;
      line-height: 2;
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.siderbar-tooltip-content {
  text-transform: capitalize;
}

.welcome-menu-wrapper {
  width: 1000px;
  z-index: 21;

  .ant-popover-inner-content {
    padding: 0;
    background: transparent;
  }
}

.app-footer {
  // padding-right: 6rem;
  background-color: $contentColor;
  padding-bottom: 2.5rem;
  .app-footer-content {
    // border-top: 1px solid #3d3d3d;
    padding: 20px 0 0 0;
    ul {
      width: 591px;
      display: flex;
      justify-content: space-around;
      margin: 0 0 10px 0;
      li {
        color: #dbdbdb;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        font-family: Helvetica Neue Medium, Arial;
      }
    }
    .app-footer-desc {
      padding: 5px 20px;
      color: #acacac;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      font-family: Helvetica Neue Regular, Arial;
    }
  }
}

.theme-light {
  color: #252525;
  a {
    color: #D04A02;
  }
  .app-container {
    background-color: #E8E8E8;
    .app-header {
      background-color: #ffffff;
      border-bottom: #e8e8e8;

      .title {
        color: #D04A02;
      }

      .divider {
        background-color: #e8e8e8;
      }

      .avatar-wrapper {
        .a-avatar {
          background-color: #D04A02;
          color: white;
        }
      }

      .question-icon {
        color: #D04A02;
      }
    }

    .app-body {
      .app-content {

        .page-wrapper {
          background-color: #e8e8e8;
        }
      }
    }
  }
  .avatar-menu-wrapper {  
    .avatar-menu {
      .avatar-menu-item {
        border-bottom: 1px solid white;
      }
    }
  }
  
  .app-footer {
    background-color: #e8e8e8;
    border-top: 1px solid #DDDDDD;
    .app-footer-content {
      ul {
        li {
          color: #D04A02;
        }
      }
      .app-footer-desc {
        color: #252525;
      }
    }
  }
}
