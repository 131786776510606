$pageMinHeight: 1024px;

$fontFamily: Helvetica Neue, Arial, sans-serif !important;
$fontFamilyRegular: Helvetica Neue Regular, Arial, sans-serif !important;
$fontFamilyMedium: Helvetica Neue Medium, Arial, sans-serif !important;

//dark theme

// background color
$headerBgColor: #000;
$pageBgColor: #121212;
$sideBarBgColor: #1b1b1b;
$contentColor: #1d1d1d;
$inputBgColor: #2c2c2c;
$avatarBgColor: #ffb300;
$modalBgColor: #1e1e1e;
$switchBgColor: #333333;
$modalBgColor: #1e1e1e;

// color
$activeColor: #e45c2b;
$hoverRed: #aa4120;
$warningRed: #e0301e;

// height
$appHeaderHeight: 68px;
$appSideBarWidth: 84px;
$inputHeight: 48px;
$appFooterHeight: 143px;

//border
$appBorder: 1px solid #242424;
$appBorder-md: 3px solid #242424;

$borderColor: #666;
$inputBorderRadius: 2px;
$borderRadius: 4px;
$inputBorderColor: #474747;

//font
$fontColor: #dbdbdb;
$fonColorLevel3: #acacac;
$fontColorLevel2: #b0b0b0;
$fontDisabledColor: #7d7d7d;

$padding: 32px;

$cardBgColor: #262626;

$iconFontColor: #999;
