@import './variables.scss';
@import './mixin.scss';

// display
.hide {
  display: none;
}
.flex-row {
  @include flex-row();
}
.flex-row-end {
  @include flex-row(flex-end);
}
.flex-row-start {
  @include flex-row(flex-start);
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-start {
  align-items: flex-start;
}

.grid-row {
  display: grid;
  grid-gap: 1.5rem;
  @media screen and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
  }
  @include grid-row();
  @include grid-column();
}
.section-row {
  margin: 1.5rem 0;
}

// text
.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-ellipsis-line2{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

// title
.page-title {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 3.125rem;
  color: $fontColor;
  line-height: 1.25;
}
.page-sub-title {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 1.875rem;
  color: $fontColor;
  line-height: 1.25;
}

// icon
.appkiticon.btn-plus-icon {
  color: $activeColor;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #fff;
  font-size: 0.75rem !important;
  font-weight: bold;
}

.ant-checkbox-wrapper {
  display: flex !important;
  align-items: center;
  span {
    color: $fontColor;
    font-size: 1.125rem;
    line-height: 1.25;
  }

  .ant-checkbox {
    top: 0;
    height: 20px;
    width: 20px;
    min-width: 20px;

    .ant-checkbox-inner {
      height: 100%;
      width: 100%;
      border: 1px solid $inputBorderColor;
      border-radius: 2px;
      background-color: transparent;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $activeColor;
        border-color: $activeColor;
      }
    }
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: $activeColor !important;
}

// width height
.full-width {
  width: 100%;
}

.btn-wrapper {
  outline: none !important;
}

.btn-wrapper.a-btn.a-btn-lg {
  padding: 0 .5rem;
  //font-size: inherit;
  border-radius: 2px;
}

// margin & padding
.mr-xs {
  margin-right: 1rem;
}
.ml-xs {
  margin-left: 1rem;
}
.mt-xs {
  margin-top: 1rem;
}
.mb-xs {
  margin-bottom: 1rem;
}
.mr-s {
  margin-right: 2rem;
}
.ml-s {
  margin-left: 2rem;
}
.mt-s {
  margin-top: 2rem;
}
.mb-s {
  margin-bottom: 2rem;
}
.pt-xs {
  padding-top: 1rem;
}

* {
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 3px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px !important;
    border-radius: 3px !important;
    background-color: rgb(133, 133, 133) !important;
  }
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem 0;
  width: 100%;
}

.content-wrapper {
  width: 100%;
  border: 1px solid $inputBorderColor;
  border-radius: 4px;
  // overflow: hidden;
}

.a-alert-pop-wrapper {
  z-index: 10000;
}