@import '../variables.scss';
.a-theme-container[theme='theme-dark'] {
  .switch-button {
    .switch-btn-txt {
      color: $fontColor;
      letter-spacing: 0;
    }

    .switch-btn.a-toggle {
      margin: 0 10px;
      box-sizing: border-box;
      height: 26px;
      width: 58px;
      border: 1px solid $inputBorderColor;
      border-radius: 20px;
      .a-toggle-mark {
        width: 100%;
        .off {
          background-color: $inputBgColor;
        }
      }
    }
  }
}
