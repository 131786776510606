@import '../variables.scss';

.app>.app-container>.app-body>.app-content:has(>.error-page-wrapper) {
  overflow: visible;
  z-index: 10;
}

.error-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$appHeaderHeight});
  margin-bottom: -$appFooterHeight;
  color: $fontColor;
  z-index: 1;
  position: relative;
  background: $pageBgColor;

  .title {
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #dbdbdb;

    img {
      margin-right: 15px;
    }

    // .appkiticon{
    //   margin-right: 1rem;
    //   &.error {
    //     color: #C52A1A;
    //   }
    // }
  }

  .message {
    margin-bottom: 50px;
    font-size: 16px;
    width: 512px;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reason {
    margin-bottom: 20px;
    width: 50vw;
    min-width: 200px;
  }
}

.theme-light {
  .error-page-wrapper {
    color: #252525;
    background-color: #E8E8E8;

    .title {
      color: #252525;
      > img {
        filter: invert(0.8);
      }
    }
  }
}