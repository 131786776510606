@mixin flex-row($hor: space-between, $ver: center) {
  display: flex;
  justify-content: $hor;
  align-items: $ver;
}

@mixin grid-row($start: 1, $end: 1) {
  grid-row-start: $start;
  grid-row-end: $end;
}

@mixin grid-column($start: 1, $end: 1) {
  grid-column-start: $start;
  grid-column-end: $end;
}
