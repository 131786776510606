@import '../variables.scss';
:root {
  --activeColor: #e45c2b;
  & .theme-light {
    --activeColor: #d04a02;
  }
}

.landing-page-wrapper {
  min-height: calc(100vh - 260px);
  .title-area {
    margin: 80px auto 0;
    text-align: center;
    p {
      font-size: 1.25rem;
      color: $fontColor;
      margin-bottom: 0px;
    }
    i {
      text-decoration: none;
      color: #7d7d7d;
      font-size: 0.75rem;
    }
  }
  .button-area {
    // height: calc(100vh - 400px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    column-gap: 60px;
    > div {
      cursor: pointer;
      width: 260px;
      height: 180px;
      background-color: $contentColor;
      color: $fontColor;
      text-align: center;
      font-size: 1.375rem;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .request-access-btn {
        display: none;
        width: 130px;
        height: 30px;
        font-size: 12px;
        color: var(--activeColor);
        border-color: var(--activeColor);
        &:hover {
          color: white;
          background-color: var(--activeColor);
        }
        &:disabled {
          opacity: 0.4;
          color: var(--activeColor)!important;
          background-color: transparent!important;
        }
      }

      &.disabled {
        // color: $fontDisabledColor;
        background-color: $sideBarBgColor;
        position: relative;
        cursor: not-allowed;
        .app-name {
          cursor: not-allowed;
          user-select: none;
          height: 26px;
          margin-bottom: 20px;
          color: $fontDisabledColor;
        }
        .request-access-btn {
          display: block;
        }
      }
    }
  }
}
.ant-modal-wrap {
  overflow: hidden !important;
}

.request-reason-modal {
  max-width: 650px;
  .ant-modal-header {
    border-bottom: none;
    padding: 30px;
    width: 600px !important;
    .ant-modal-title {
      color: #dbdbdb;
      font-size: 30px;
      font-weight: bold;
    }
  }
  .ant-modal-close-icon {
    margin-top: 30px;
    margin-right: 34px;
    > svg {
      width: 24px;
      height: 24px;
    }
  }
  .ant-modal-body {
    //request-reason
    padding-top: 0;
    > div:nth-child(1) {
      font-size: 14px;
      text-transform: uppercase;
      color: #acacac;
      margin-bottom: 10px;
    }
    .reason-input {
      min-height: 150px;
      padding: 10px 11px;
      font-size: 16px;
      resize: none;
    }
    // a {
    //   text-decoration: underline;
    // }
  }
  .ant-modal-footer {
    .ant-btn {
      height: 34px;
      // width: 87px;
      // font-size: 14px;
      font-weight: 500;
      // text-transform: uppercase;
      border-color: var(--activeColor);
      color: var(--activeColor);
    }
    .ant-btn-primary {
      color: white;
      padding: 0 6px;
      &:disabled {
        background-color: #dbdbdb;
        color: white;
        border-color: #dbdbdb;
        &:hover {
          color: white;
          background-color: #dbdbdb;
        }
      }
      .anticon-loading {
        padding-right: 0;
      }
      &.ant-btn-loading {
        > span:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.theme-light {
  .landing-page-wrapper {
    .title-area {
      p {
        color: #252525;
        font-weight: 550;
      }
      i {
        color: #474747;
      }
    }
    .button-area {
      .app-name {
        font-weight: bold;
      }
      > div {
        background-color: #ffffff;
        color: #252525;

        .request-access-btn {
          color: #d04a02;
          border-color: #d04a02;
          &:hover {
            background-color: #d04a02;
          }
          &:disabled {
            &:hover {
              color: #d04a02;
            }
          }
        }

        &.disabled {
          background-color: #ffffff;
          .app-name {
            color: $fontDisabledColor;
          }
          .request-access-btn {
            display: block;
            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.theme-light .request-reason-modal {
  .ant-modal-content {
    background-color: #e8e8e8;
  }
  .ant-modal-header {
    background-color: #e8e8e8;
    .ant-modal-title {
      color: #252525;
    }
  }
  .ant-modal-close-icon {
    color: #252525;
  }
  .ant-modal-body {
    > div:nth-child(1) {
      color: #474747;
    }
    .reason-input {
      background-color: #ffffff;
      color: #252525;
      border-width: 0;
      &::placeholder {
        color: #acacac;
      }
    }
  }
  .ant-modal-footer {
    border-top: 1px solid #dddddd;
    .ant-btn {
      border-color: #d04a02;
      color: #d04a02;
    }
    .ant-btn-primary {
      color: white;
      padding: 0 6px;
      background-color: var(--activeColor);
      &:disabled {
        background-color: #dbdbdb;
        color: white;
        border-color: #dbdbdb;
        &:hover {
          color: white;
          background-color: #dbdbdb;
        }
      }
    }
  }
}

.theme-light .a-alert.one-line.a-alert-success {
  background-color: #e3e3e3;
  .a-alert-text-content {
    color: #252525;
  }
}