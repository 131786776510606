@import '../variables.scss';
:root {
  --regiBgColor: #000;
  --regiWrapperBgColor: #1d1d1d;
  .theme-light {
    --regiBgColor: #fff;
    --regiWrapperBgColor: #f3f3f3;
  }
}

.a-theme-container {
  overflow: auto;
}
.password-tooltip {
  color: #dbdbdb;
  margin-bottom: 6px;
  font-size: 0.75rem;
}
.register-page-wrapper {
  min-height: 100vh;
  width: 100%;
  background-color: var(--regiBgColor);
  display: flex;
  justify-content: center;
  color: var(--fontColor);
  .footer-login {
    color: #acacac;
    font-size: 1rem;
  }
  .signin-btn {
    padding: 0;
  }

  .register-container {
    .logo-wrapper {
      height: 162px;
      .logo {
        width: 80px;
        height: 62px;
      }
    }

    .register-page {
      //   height: 600px;
      margin-bottom: 4rem;
      padding-bottom: 7.125rem;
      width: 800px;
      background: var(--regiWrapperBgColor);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;

      .text {
        font-weight: bold;
        width: 100%;
        font-size: 1.875rem;
        text-align: center;
        white-space: nowrap;
        text-transform: capitalize;
        margin: 2.5rem 0;
        color: #dbdbdb;
      }

      .ant-form-vertical {
        font-size: 1rem;

        .ant-form-item {
          margin-bottom: 20px;
          .ant-form-item-label {
            //   font-size: 2rem;
            padding: 0 0 14.5px;
            > label {
              width: 100%;
              font-size: 1rem;
              color: #acacac;
              .form-item-password {
                width: 100%;
                display: flex;
                justify-content: space-between;
              }
              .ant-form-item-tooltip {
                height: 18px;
                position: absolute;
                right: 0;
                .icon-information-fill {
                  color: #7d7d7d;
                  font-size: 20px;
                }
              }
            }
            label.ant-form-item-required {
              &::before {
                content: '';
              }
            }
          }
          .ant-input {
            height: 48px;
          }
          #register_password,
          #register_confirm {
            height: 38px;
          }
          .ant-input[disabled] {
            background-color: rgba(255, 255, 255, 0);
            height: 50px;
          }
        }
      }

      .btn-wrapper {
        font-size: 1rem;
        margin: 20px 0 10px;
        cursor: pointer;
        width: 29.38rem;
        height: 3.125rem;
      }
    }
  }
}

.theme-light {
  .register-page-wrapper .register-container .register-page .text {
    color: #252525;
  }
  .register-page-wrapper .footer-login {
    color: #474747;
  }
  .register-page-wrapper
    .register-container
    .register-page
    .ant-form-vertical
    .ant-form-item
    .ant-form-item-label
    > label {
    color: #474747;
  }

  input.ant-input,
  .ant-input-password,
  .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
    background: #ffffff !important;
    border: 1px solid #d1d1d1;
    outline-style: none;
    color: #252525;
    box-shadow: none;
  }
  .ant-input-password > input {
    border-style: none !important;
  }
  input.ant-input:disabled,
  input.ant-input .ant-input-disabled {
    border-color: #d1d1d1;
  }
  .ant-btn-primary {
    background-color: #d04a02;
  }
  .ant-btn-link {
    color: #d04a02;
  }
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    color: #d04a02;
  }
  .ant-form-item-has-error .ant-input:focus {
    border-color: #d04a02;
  }
  .ant-input-password {
    .anticon-eye-invisible,
    .anticon-eye {
      svg {
        fill: #acacac;
      }
    }
  }
  div.a-tooltip-inner {
    background-color: #2c2c2c;
  }
}
