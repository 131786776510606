@import '../variables.scss';

input.ant-input {
  width: 100%;
  height: $inputHeight;
  background: $inputBgColor;
  border: 1px solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  box-shadow: none;
  &:focus,
  .ant-input-focused {
    box-shadow: none;
  }
  &.invalid {
    border-color: $warningRed;
  }
  &:disabled, .ant-input-disabled {
    color: $fontDisabledColor;
    border: 1px solid $inputBorderColor;
    border-radius: $inputBorderRadius;
    background-color: $inputBgColor;
  }
}
.ant-input.ant-input-disabled{
  background: $inputBgColor;
}

input.input-uppercase {
  text-transform: uppercase;
}