@import '../variables.scss';

.progress-chart {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  .progress-number {
    font-size: 16px;
    margin-right: 5px;
    .default-color {
      color: #ffb600;
    }
    .blue {
      color: #4DACF1;
    }
    .gray {
      color: #7d7d7d;
    }
  }
  .icon-circle-checkmark-fill {
    font-size: 24px;
    color: #4DACF1;
  }
}
