@import '../variables.scss';
@import '../common.scss';

.modal-wrapper {
  .ant-modal {
    padding: 0;
  }
  
  .ant-modal-content {
    background-color: $contentColor;
    color: $fontColor;
    min-width: 300px;

    .ant-modal-header {
      background-color: transparent;
      border-bottom: 1px solid $inputBorderColor;
      padding: 32px 30px;
      .ant-modal-title {
        color: $fontColor;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 30px;
      }
    }

    .ant-modal-footer {
      border-top: 1px solid $inputBorderColor;
      padding: 30px 30px;
    }

    .ant-modal-close {
      top: 35px;
      right: 30px;
      font-size: 16px;
      line-height: 30px;
      color: $fontColor !important;
    }

    .centerLoading {
      text-align: center !important;
    }

    @media screen and (max-width: 800px) {
      width: 95vw !important;
    }
  }

  .btn-wrapper.a-btn.a-btn-lg {
    min-width: 80px;
    padding: 0 1rem;
  }

  .btn-wrapper.a-btn.a-btn-lg:disabled {
    opacity: .3;
    color: inherit;
  }

  .btn-wrapper.a-btn.a-btn-secondary {
    color: $activeColor;
    border-color: $activeColor;
    &:hover {
      background-color: rgb(53, 43, 38);
    }
    &:active {
      background-color: rgb(70, 44, 36);
    }
  }

  .btn-wrapper.a-btn-primary {
    background-color: $activeColor;
  }

  .modal-show {
    animation: modalFadeIn 0.3s;
  }
  
  .a-loading {
    margin: 50px auto !important;
  }

  .modal-hide {
    animation: modalFadeOut 0.3s;
  }

  @keyframes modalFadeIn {
    0% {
      transform: translate3d(0, -100%, 0);
      opacity: 0;
    }
  
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  
  @keyframes modalFadeOut {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  
    60% {
      opacity: 0;
    }
  
    100% {
      transform: translate3d(0, -100%, 0);
      opacity: 0;
    }
  }
}