@import '../variables.scss';

.logo-wrapper {
  height: $appHeaderHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: $appBorder;
  background-color: $headerBgColor;

  .logo {
    background: url('../../assets/imgs/PwC_logo_mono_outline_white_rgb.jpg');
    height: 44px;
    width: 58px;
    background-size: cover;
  }
}

.theme-light {
  .logo-wrapper {
    background-color: #fff;
    border-width: 0;
  }
  .logo {
    background: url('../../public/pwc-light.png');
    background-size: cover;
  }
}
