@import '../variables.scss';

.ant-select.ant-select-single {
  width: 100%;
  height: $inputHeight;
  background: $inputBgColor;
  //border-radius: $inputBorderRadius;

  .ant-select-selector {
    padding: 0 10px;
    height: 100%;
    border: 1px solid $inputBorderColor;

    .ant-select-selection-search-input {
      //height: 100%;
      //font-size: 16px;
      //color: #dbdbdb;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled) {
    .ant-select-selector {
      border: 1px solid $activeColor;
      box-shadow: unset;
    }
  }

  .ant-select-arrow, .ant-picker-suffix{
    font-size: 16px;
    top: 50%;
    right: 20px;
  }
}

.ant-select-item-option {
  height: 36px;

  &.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: rgba(250, 89, 39, 0.6);
  }
}

.ant-select.ant-select-disabled {
  .ant-select-selector{
    background: #2c2c2c !important;
    cursor: not-allowed;
  }
}