@import '../variables.scss';

$accordionHeaderBgColor: #1d1d1d;
$accordionContentBgColor: #121212;
$accordionItemHeight: 48px;
$border: 1px solid $inputBorderColor;

.accordion-wrapper {
  border-top: $border;
  overflow: hidden;
  .a-accordion-item-wrapper {
    margin-bottom: 0;
    &:last-child {
      .a-accordion-item-header {
        transition: .5s ease-in-out;
        border-bottom-color: transparent;
      }
    }
    &.is-active:last-child {
      .a-accordion-item-header {
        border-bottom: $border;
      }
    }
    .a-accordion-item-header {
      height: $accordionItemHeight;
      background-color: $accordionHeaderBgColor !important;
      border-left: $border;
      border-right: $border;
      border-bottom: $border;
      color: $fontColor !important;
      outline: none;
      font-weight: normal;
      &:hover {
        background-color: $accordionHeaderBgColor;
      }
      .a-accordion-icon {
        line-height: 48px;
        color: $fontColor !important;
      }
    }
    .a-accordion-content {
      min-height: $accordionItemHeight;
      border-right: $border;
      border-left: $border;
      border-bottom: $border;
      background-color: $accordionContentBgColor !important;
      color: $fontColor !important;
      .with-bottom-padding {
        padding-bottom: 0;
      }
    }
    .inner-children-item {
      display: flex;
      align-items: center;
      margin: 0 -.625rem;
      padding: .625rem;
      min-height: $accordionItemHeight;
      border-bottom: $border;
      &:last-child {
        border-bottom-color: transparent;
      }
    }
  }
}
