@import '../variables.scss';

.ant-input-number {
  width: 100%;
  height: $inputHeight;
  line-height: $inputHeight;
  background: $inputBgColor;
  border: 1px solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  box-shadow: none;
  &:focus,
  .ant-input-number-focused {
    box-shadow: none;
  }
  &.invalid {
    border-color: $warningRed;
  }
  &:disabled, .ant-input-number-disabled {
    color: $fontDisabledColor;
    border: 2px solid $inputBorderColor;
    border-radius: $inputBorderRadius;
    background-color: $inputBgColor;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-input-number-focused {
  border-color: #e45c2b !important;
}

.with-dollar-prefix {
  .ant-input-number-input-wrap {
    padding-left: 12px;
    &::before {
      content: '$';
      display: inline-block;
      width: 12px;
      position: absolute;
      top: 0;
      left: 10px;
    }
  }
  
}