@import '../variables.scss';

.ant-picker {
  width: 100%;
  height: $inputHeight;
  background: $inputBgColor;
  box-shadow: none;
  &:not(.ant-picker-focused):not(:hover) {
    border-color: $inputBorderColor;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-clear {
    background: $inputBgColor;
  }

}
