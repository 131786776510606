@import '../../variables.scss';

.privacy-statement-container {
  @media screen and (min-width: 960px) {
    margin: -2rem -3rem -9rem;
    padding-right: 6rem;
    background: #000;
    overflow: hidden;
    .inner-container {
      padding: 5rem 6rem;
      background: $pageBgColor;
      border-right: 1px solid #222;
      min-height: calc(100vh - #{$appHeaderHeight});
    }
  }
  .page-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px auto 45px;
    .page-title {
      margin-bottom: 0;
    }
    .btn-wrapper {
      height: 34px;
      width: 78px;
      text-align: center;
      line-height: 34px;
    }
  }
  .tabs-container {
    .ant-tabs {
      .ant-tabs-tab {
        height: 46px;
        border: none;
        border-radius: 6px 6px 0 0;
        min-width: 97px;
        .ant-tabs-tab-btn {
          color: #fff;
          margin: 0 auto;
          text-align: center;
        }
      }
      .ant-tabs-tab-active {
        background: #fa5927;
      }
      .tabs-ul {
        li {
          color: #dbdbdb;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
    }
    .privacy-p {
      color: #dbdbdb;
      // text-indent: 20px;
      > h2 {
        margin: 2.5rem 0;
        font-size: 1.875rem;
      }
      > h3 {
        font-size: 1.5rem;
        > strong > em {
          font-style: italic;
          color: #fa5927;
        }
      }
      p {
        > strong > em {
          font-style: italic;
          font-size: 1.5rem;
        }
        line-height: 30px;
        font-size: 1.125rem;
        margin-bottom: 20px;
        &:nth-of-type(1) {
          margin-bottom: 40px;
        }
        &:nth-of-type(2) {
          margin-bottom: 60px;
        }
      }
      > table {
        border: #dbdbdb 1px solid;
        > tbody > tr {
          display: table-row;
          border: #dbdbdb 1px solid;
          > td {
            border: #dbdbdb 1px solid;
            padding-right: 1rem;
            align-content: center;
            > p {
              padding-left: 0.125rem;
              padding-top: 0.5rem;
              margin-bottom: 0.5rem !important;
            }
          }
        }
      }
      .span-link {
        color: #fa5927;
        cursor: pointer;
      }
      > ul {
        padding-left: 0.5rem;
        list-style: circle;
        display: list-item;
        margin-bottom: 1rem;
        > li {
          &::marker {
            content: '\2022';
            color: #fa5927;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
          font-size: 1rem;
          margin-left: 0.25rem;

          > span {
            margin-left: 0.25rem;
          }
          > p {
            margin-left: 0.25rem;
            font-size: 1rem;
          }
          list-style: circle;
          display: list-item;
        }
      }
      .internal-link {
        color: #fa5927;
        cursor: pointer;
      }
    }
  }
}
