@import '../variables.scss';

.terms-acceptance-modal {
  margin-top: 4vh;
  .ant-modal {
    width: 600px !important;
  }
  .ant-modal-body {
    > div {
      > p {
        font-size: 1rem;
        font-weight: 500;
        > span {
          color: $activeColor;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      color: #dbdbdb;
      > h1 {
        font-weight: bold;
        font-size: 1.875rem;
      }
      .terms-content {
        span,
        a {
          cursor: none;
          pointer-events: none;
          text-decoration: none;
          color: #dbdbdb;
        }
        &::-webkit-scrollbar {
          width: 10px;
          background-color: #1e1e1e;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgb(141, 141, 141);
          border-radius: 5px;
        }
        > table {
          border: #dbdbdb 1px solid;
          > tbody > tr {
            display: table-row;
            border: #dbdbdb 1px solid;
            > td {
              border: #dbdbdb 1px solid;
              padding-right: 1rem;
              align-content: center;
              > p {
                padding-left: 0.125rem;
                padding-top: 0.5rem;
                margin-bottom: 0.5rem !important;
              }
            }
          }
        }
        height: 52vh;
        overflow-y: scroll;
        font-weight: normal;
        font-size: 1rem;
        -webkit-mask-image: linear-gradient(
          to bottom,
          black 80%,
          transparent 100%
        );
        mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
      }
    }
    padding: 1.875rem;
  }
  .ant-modal-footer {
    padding: 1.875rem;
  }
  .ant-btn {
    border-top: 1px solid #3d3d3d;
    display: inline-block;
    width: 6rem;
    height: 3.125rem;
    font-size: 1rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    border-color: $activeColor;
    color: $activeColor;
    margin-left: 1.25rem;
    &:disabled {
      border-color: #dbdbdb;
      background: grey;
    }
  }
  .ant-btn-loading {
    > span:nth-child(2) {
      display: none;
    }
  }
  .ant-btn-primary {
    color: #ffffff;
  }
}

.theme-light {
  .terms-acceptance-modal {
    .ant-modal-content {
      background-color: #e3e3e3;
    }
    .ant-modal-body {
      > div {
        h1, h2, h3 {
          color: #252525;
        }
        > p {
          > span {
            color: #d04a02;
          }
        }
        color: #252525;
        .terms-content {
          span,
          a {
            color: #474747;
          }
          &::-webkit-scrollbar {
            background-color: #e3d3d3;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgb(141, 141, 141);
          }
          > table {
            border: #dddddd 1px solid;
            > tbody > tr {
              border: #dddddd 1px solid;
              > td {
                border: #dddddd 1px solid;
              }
            }
          }
        }
      }
    }
    .ant-btn {
      border-top: 1px solid #dddddd;
      border-color: #d04a02;
      color: #d04a02;
    }
    .ant-btn-primary {
      background-color: var(--activeColor);
      color: #ffffff;
    }
  }
}
