.a-alert-pop-wrapper {
  z-index: 99;
  left: calc(50% - 210px);
  transform: translateY(50px);
  opacity: 0.5;
  animation: popup-animation 1s ease forwards;
  .popup-error {
    z-index: 5;
  }
}
@keyframes popup-animation {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(80px);
    opacity:1;
  }
}
