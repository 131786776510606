.ant-modal {
  width: auto !important;
}
.ant-modal-wrap {
  display: flex;
}
.popup-success {
  z-index: 1000;
  .a-countdown-wrapper {
    display: none !important;
  }
}

.alert-box {
  font-weight: bold;
  .ant-modal-confirm-body {
    display: flex;
  }
  top: 0;
  margin: auto;
  .ant-modal-close {
    display: block;
  }
  .ant-modal-content {
    width: 36.25rem;
  }
  .ant-modal-confirm.ant-modal-body,
  .ant-modal-body {
    padding: 0;
    .ant-modal-confirm-body {
      border-bottom: 1px solid #555;
      padding: 30px 20px;
      .warning-badge {
        line-height: 1.875rem;
        height: 1.875rem;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 0.875rem;
        padding: 0 0.625rem;
        font-size: 0.875rem;
        font-weight: 700;
        color: #424242;
        background-color: #ffbf20;
        margin-right: 9px;
      }
    }

    .ant-modal-confirm-content {
      margin: 0;
      font-size: 1.125rem;
      .ant-modal-close {
        display: block;
      }
    }
    .ant-btn > .ant-btn-loading-icon .anticon svg {
      display: none;
      padding: 0;
      margin: 0;
    }
    .ant-modal-confirm-btns {
      margin: 30px 20px;
      display: flex;
      .ant-btn {
        height: 2.125rem;
        font-weight: bold;
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cancel-btn {
        color: #e45c2b;
        border-color: #e45c2b;
      }
    }
  }
}

