@import '../variables.scss';

.breadcrumb-wrapper {
  font-family: Helvetica Neue, Arial;

  a {
    text-decoration: unset;
  }

  .breadcrumb-text {
    color: $activeColor;
    font-size: 16px;

    &:hover {
      color: $fontColor;
    }
  }

  .ant-breadcrumb-separator {
    color: $fonColorLevel3;
    margin: 0px 5px;
  }
}
