@import '../variables.scss';
@import '../common.scss';

.dropdown-user-list{
  min-width: 400px !important;
  width: 400px !important;
  .ant-select-item-option-selected, .ant-select-item-option-active{
    color: #fff !important;
  }
  .ant-select-item-option{
    height: auto !important;
    color: #B5B5B5;
    padding: 10px;
    border-bottom: 1px solid #666;
    .dropdown-user-name{
      font-size: 16px;
      font-style: italic;
      margin-bottom: 8px;
    }
    .dropdown-user-detail{
      font-size: 14px;
      margin-bottom: 4px;
    }
    &:last-child{
      border: none;
    }
    .ant-select-item-option-state .anticon-check{
      color: #fff !important;
    }
  }
}