@import '../variables.scss';

.ant-select.multiple-select {
  width: 100%;
  min-height: $inputHeight;
  background: $inputBgColor;
  //border-radius: $inputBorderRadius;

  .ant-select-selector {
    padding: 0 10px;
    // height: 100%;
    min-height: $inputHeight;
    border: 1px solid $inputBorderColor;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-arrow {
    width: 18px;
    height: 18px;
    top: 50%;
    right: 15px;
    font-size: 18px;
  }

  .ant-select-selection-item{
    border-radius:15px;
    height: 30px;
    line-height: 30px;
    padding: 0 8px 0 8px;
    .ant-select-selection-item-content{
      margin-right: 20px;
      line-height: 26px;
    }
    .ant-select-selection-item-remove{
      font-size: 1rem;
      line-height: 26px;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled) {
    .ant-select-selector {
      border: 1px solid $activeColor;
      box-shadow: unset;
    }
  }
}

.ant-select.multiple-select.ant-select-disabled {
  .ant-select-selector{
    background: #2c2c2c !important;
    cursor: not-allowed;
  }

  .appkiticon {
    color: #434343 !important;
  }
}