@import '../variables.scss';
:root {
  --activeColor: #e45c2b;
  & .theme-light {
    --activeColor: #d04a02;
  }
}

.login-page-wrapper {
  min-height: calc(100vh - 210px);
  width: 100%;
  background-color: $pageBgColor;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $fontColor;
  .loading-wrapper-fullscreen {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .login-page-content {
    // margin-top: -100px;
    .title {
      color: $fontColor;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      text-align: center;
      margin-bottom: 20px;
    }

    .text {
      font-size: 1rem;
      color: #dbdbdb;
      width: 520px;
      margin-bottom: 60px;
      text-align: center;
    }

    .button-column {
      // width: 470px;
      // height: 160px;
      display: flex;
      justify-content: space-between;
      button {
        width: 240px !important;
        height: 50px !important;
      }
      button:nth-of-type(2) {
        margin-left: 30px;
      }
    }
  }
}

.theme-light {
  .login-page-wrapper {
    background-color: #E8E8E8;
    color: #252525;
  
    .login-page-content {
      .title {
        color: #252525;
      }
  
      .text {
        color: #474747;
      }
    }
    .a-btn.a-btn-primary {
      background-color: var(--activeColor);
    }
    .a-btn.a-btn-secondary {
      color: var(--activeColor);
      border-color: var(--activeColor);
    }
  }
}