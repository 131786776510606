@import '../../variables.scss';

.entanglements-table-container,
.transitions-table-container {
  .ant-table-content {
    background: #1d1d1d;
  }

  .operationalization-title-filter .widget-switch-container .widget-switch-checked-text, .operationalization-title-filter .widget-switch-container .widget-switch-unchecked-text{
    width: 202px;
  }

  .ant-table-cell {
    border-bottom-color: #474747;
    border-top-color: #474747;
    word-break: break-word;

    .model-description-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
  }

  .export-dropdown {
    .appkiticon {
      font-size: 12px !important;
      margin-right: 0;
      margin-left: 5px;
    }
  }

  .export-dropdown-memu {
    .ant-dropdown-menu-item {
      .menu-text {
        height: 36px;
        line-height: 36px;
      }
    }
    .ant-dropdown-menu-item-active {
      background-color: rgba(228,92,43,0.6);
      
      .menu-text {
        color: #fff !important;
      }
    }
  }

  .ant-table-thead {
    >tr {
      >th {
        vertical-align: middle;
        background: #242424;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 500;
        padding: 10px;

        >div>p {
          margin-bottom: 0;
        }
      }

      .merged-column-header {
        display: flex;
        justify-content: center;

        .entangled-header {
          display: flex;
          width: 8rem;
          justify-content: center;
          padding: 10px;

          >p {
            margin: 0;
          }
        }

        .description-header-transitions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          // margin-left: 1rem;
          >p {
            margin-bottom: 0 !important;
          }
        }

        .description-header {
          //padding-top: 0.25rem;
          width: 100%;
          text-align: center;

          >p {
            margin-bottom: 0 !important;
          }
        }

        .description-header-transitions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          >p {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    >tr {
      .geographyColumn {
        min-width: 15.1rem;
      }
      .select-wrapper{
        .ant-select-open, .ant-select-focused {
          z-index: 2 !important;
        }
      }
    }
  }
}

.entanglements-title,
.transitions-title {
  margin-bottom: 30px;
}

.buttons-container {
  margin-bottom: 30px;
}

.filter-function-container {
  height: 67px;

  .filter-title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .filter-detail {
    width: 161px;

    .ant-select.ant-select-single {
      height: 35px;
    }
  }
}

.filter-tsa-container {
  height: 81px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filter-service-container {
  display: flex;
  flex-direction: column;
  height: 67px;
  // margin-right: 11px;

  .filter-service-title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .filter-service-detail {
    display: flex;
    flex: 1;
    align-items: center;

    .ant-radio-wrapper {
      margin-right: 22px;
      font-size: 16px;

      .ant-radio-inner {
        width: 20px;
        height: 20px;

        &::after {
          top: 4px;
          left: 4px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.filter-statistics-container {
  margin-bottom: 21px;
  padding-right: 100px;
}

.entanglement-operation-btn {
  width: 100px;
  margin-right: 10px;
}

.model-description-tooltip {
  white-space: pre-line !important;
  .ant-tooltip-inner {
    color: #dbdbdb;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}