@import '../variables.scss';

.widget-switch-container {
  position: relative;
  border-radius: 2px;
  font-size: 14px;
  padding: 4px 3px;
  overflow: hidden;
  background-color: $switchBgColor;
  white-space: nowrap;

  .widget-switch-checked-text,
  .widget-switch-unchecked-text {
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-weight: 500;
    width: 92px;
    text-align: center;
    color: $fonColorLevel3;
    word-break: normal;
    height: 27px;
    line-height: 27px;
    border-radius: 2px;
    .switch-content {
      position: relative;
      z-index: 2;
    }

    .widget-switch-inner-button {
      position: absolute;
      top: 0;
      width: 100%;
      border-radius: 2px;
      bottom: 0;
      z-index: 1;
      background: $activeColor;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-direction: normal;
    }
  }

  .covered-text{
    .switch-content{
      color: #fff;
    }
  }

  .inner-button-checked {
    animation-name: checkedPositionAni;
  }

  .inner-button-unchecked {
    animation-name: uncheckedPositionAni;
  }

  .show-inner-button{
    display: block;
  }

  .hide-inner-button{
    display: none;
  }

  @keyframes checkedPositionAni {
    0% {
      left: 100%;
    }
    100% {
      left: 0;
    }
  }

  @keyframes uncheckedPositionAni {
    0% {
      left: 0;
    }
    100% {
      left: 100%;
    }
  }
}
