@import '../variables.scss';

.avatar {
  background-color: $avatarBgColor;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  .ant-avatar-string {
    font-family: $fontFamily;
  }
}
.avatar-menu.ant-tooltip {
  max-width: 100%;
  .ant-tooltip-content {
    margin-right: 5px;
  }
}

.avatar-menu .ant-tooltip-inner {
  padding: 0;
  width: min-content;
  min-width: 310px;
  max-width: 20rem;
  padding: 8px 8px 0;
  background-color: #1a1a1a;
  .avatar-menu-wrapper {
    .user-contain {
      padding: 8px 8px 16px;
      overflow: hidden;
      border-bottom: 1px solid rgb(102, 102, 102);
      .user-contain-avatar {
        font-weight: 700;
        background-color: $activeColor;
      }

      .user-info {
        margin-left: 10px;
        min-height: 48px;

        p {
          margin: 0;
        }

        .user-info-email {
          color: #9e9e9e;
          font-weight: 700;
        }
      }
      .user-info-name {
        font-weight: 700;
      }
    }

    .divide-line {
      width: 96%;
      border-bottom: 1px solid #fff;
    }

    .privacy-statement-NavLink {
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding-left: 20px;
      font-size: 14px;
      text-align: left;
      a {
        width: 100%;
        color: #acacac;
      }
    }
    .privacy-statement-NavLink:hover {
      background-color: rgba(250, 89, 39, 0.5);
      a {
        color: #dbdbdb;
      }
    }

    .logout-contain {
      padding-left: 8px;
      height: 54px;
      line-height: 54px;
      font-weight: 700;
      font-size: 12px;
    }
  }
}

.theme-light {
  .avatar {
    background-color: #d04a02;
    color: #ffffff;
  }

  .ant-tooltip-arrow > span {
    background-color: white;
  }

  .avatar-menu .ant-tooltip-inner {
    background-color: white;
    color: #252525;
    .avatar-menu-wrapper {
      .user-contain {
        border-bottom: 1px solid #dddddd;
        .user-contain-avatar {
          background-color: #d04a02;
        }

        .user-info {
          .user-info-email {
            color: #474747;
            font-weight: 500;
          }
        }
      }

      .divide-line {
        border-bottom: 1px solid #fff;
      }

      .privacy-statement-NavLink {
        a {
          color: #acacac;
        }
      }
      .privacy-statement-NavLink:hover {
        background-color: rgba(250, 89, 39, 0.5);
        a {
          color: #dbdbdb;
        }
      }
    }
  }
}
