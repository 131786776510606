@import '../variables';
.mr-24{
  margin-right: 24px;
}
.entanglements-overview-container{
  display: flex;
  font-size: 14px;
  color: $fontColor;
  margin-left: 40px;
  .entanglements-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:last-child{
      align-items: flex-start;
      margin-right: 0 !important;
    }
    .statistics-title{
      margin-bottom: 14px;
      line-height: 20px;
    }
    .tsa-statistics-title{
      min-width: 76px;
    }
    .statistics-details{
      line-height: 16px;
      .statistics-detail{
        display: flex;
        align-items: baseline;
        margin-right: 30px;
        line-height: 27px;
        .statistics-value{
          font-size: 22px;
          font-weight: 500;
          color: $activeColor;
        }
        .statistics-label{
          margin-left: 5px;
          color: $fonColorLevel3;
        }
        &:last-child{
          margin-right: 0 !important;
        }
      }
    }
  }
  .divider-line{
    margin: 0 30px;
    border-right: 1px solid $inputBorderColor;
  }
}
