@import '../variables.scss';

.ant-drawer-expand.ant-drawer-open {
	left: 84px !important;
	width: calc(100% - 84px) !important;
}

.ant-drawer-collapse.ant-drawer-open {
	left: calc(100% - 600px) !important;
	width: 600px !important;
}

.ant-drawer-content-wrapper {
	margin-top: $appHeaderHeight;
	transition: all 0.5s !important;

	.ant-drawer-body {
		position: relative;
		padding: 2rem 2.5rem 8rem;
		padding-top: 0;
	}
}

.ant-drawer-mask {
	background-color: transparent;
}

.drawer-close {
	font-size: 20px;
	color: $iconFontColor;
}

// .ant-drawer-right.ant-drawer {
// 	z-index: 19;
// }

.expand-title {
	display: flex;
	justify-content: space-between;

	.ant-btn-link {

		&:active,
		&:focus,
		&:hover {
			color: #fa5927;
		}

		padding: 0;
		position: relative;
		top:-10px;
		font-size: 1rem;
	}

	b {
		font-size: 20px;
	}
}

.drawer-title {
	font-size: 1.875rem;
	// display: flex;
	// justify-content: space-between;
	margin: 10px 0 30px;

	// span {
	// 	width: 55%;
	// }

	// span:last-child {
	// 	width: 45%;
	// 	text-align: right;
	// }

	.drawer-title-wrap {
		.sub-title {
			color: #E45C2B;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 17px;
			margin-left: 20px;
			cursor: pointer;
		}
	}
	
	.drawer-title-vertical-wrap {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		
		.sub-title {
			text-align: left;
			margin-left: 0;
		}
	}
}