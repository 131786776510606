@import '../variables.scss';

.cascader-box {
  width: 100%;
  .ant-cascader-input {
    height: $inputHeight;
    background: $inputBgColor !important;
    border: 1px solid $inputBorderColor;
    border-radius: $inputBorderRadius;
    box-shadow: none;
    &:focus,
    .ant-input-focused {
      box-shadow: none;
      border-color: $activeColor;
    }
  }
}
.ant-cascader-menus {
  min-height: 80px;
  height: 150px;
  div {
    display: flex;
    .ant-cascader-menu {
      height: 150px;
      flex: 1;
      li {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .ant-cascader-menu-item:hover {
        background: rgba(250, 89, 39, 0.6);
      }
      .ant-cascader-menu-item-active{
        background: rgba(250, 89, 39, 0.6);
      }
      .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background: rgba(250, 89, 39, 0.6);
      }
    }
  }
}