@import '../variables.scss';

.loading-wrapper-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.4);
  // margin-top: $appHeaderHeight;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme-light {
  .loading-wrapper-fullscreen {
    background-color: rgba($color: #f3f3f3, $alpha: 0.4);
  }
}