@import '~appkit-react/style/appkit-react.default.css';
// @import '~antd/dist/antd.css';
 @import '~antd/dist/antd.dark.css';

// @import '~antd/dist/antd.compact.css';

#root {
  --backgroundColor: #000;
  .theme-light {
    --backgroundColor: #f3f3f3
  }
}

body,
html {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
  div {
    box-sizing: border-box;
  }
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Helvetica Neue, Arial !important;
}

.a-theme-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loadable-loading-wrapping {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

textarea {
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #2c2c2c;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(141, 141, 141);
    border-radius: 5px;
  }
}
.a-alert-wrapper {
  width: fit-content!important;
}
@media screen and (max-width: 1079px) {
  html body {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1920px) {
  html body {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1921px) {
  html body {
    font-size: 22px !important;
  }
}
