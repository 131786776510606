@import '../variables.scss';
@import '../common.scss';

.ant-select-tree-list {
  .ant-select-tree-treenode-switcher-close {
    display: block;
    height: 36px;
    line-height: 36px;

    .ant-select-tree-checkbox {
      float: right !important;
      margin: 10px 8px 0 0 !important;
    }

    .ant-select-tree-node-content-wrapper {
      float: left !important;
      width: 90%;
      height: 36px;
      line-height: 36px;
      background-color: none !important;
      font-size: 16px;
    }

    .ant-select-tree-node-content-wrapper:hover {
      background-color: rgba(250, 89, 39, 0);
    }
  }

  .ant-select-tree-treenode-switcher-close:hover {
    background-color: rgba(250, 89, 39, 0.6);

    .ant-select-tree-checkbox {
      border: #fff;
    }

    .ant-select-tree-node-content-wrapper {
      background-color: none !important;
    }
  }
}

.dropdown {
  .ant-checkbox-wrapper {
    height: 36px;
    line-height: 36px;
    display: block !important;
    padding-left: 4px !important;
    font-size: 16px;

    .ant-checkbox {
      float: right !important;
      margin: 10px 8px 0 0 !important;
      padding: 0 4px;

      span {
        width: 16px;
        height: 16px;
      }

      &.ant-checkbox-checked::after {
        display: none;
      }
    }

    span {
      font-size: 14px;
      margin: 0;
    }
  }

  .ant-select-tree-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
  }

  .ant-checkbox-wrapper:hover {
    background-color: rgba(250, 89, 39, 0.6);

    .ant-checkbox {
      margin: 10px 8px 0 0 !important;
    }

    .ant-checkbox-checked::after {
      width: 16px;
      height: 16px;
    }
  }

  span {
    margin-left: 0;
    padding: 0;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
}