@import '../variables.scss';

.form-item-wrapper {
  position: relative;
  margin-bottom: 1.5625rem;

  .form-label-wrapper {
    display: block;
    margin-bottom: 0.25rem;
    color: $fonColorLevel3;

    .required-marker {
      margin-left: .25rem;
      color: $warningRed;
    }
  }
  .error-message {
    margin: .25rem 0 0;
    min-height: 16px;
    line-height: 1.25;
    font-size: .75rem;
    color: $warningRed;
  }

  .a-input-text {
    font-size: 1rem;
    color: $fontColor !important;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .a-form-error {
    position: absolute;
    display: flex;
    bottom: -1.25rem;
  }
}

.form-label {
  font-size: 1rem;
  color: $fonColorLevel3;
  text-transform: capitalize;
}

.a-theme-container[theme='theme-dark'] {
  .form-item-wrapper {
    .a-input-text {
      background-color: $inputBgColor;
      border: 1px solid $inputBorderColor;

      &:focus {
        background-color: $inputBgColor;
      }
    }
  }
}
